import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './Auth';
import ProtectedPage from './ProtectedPage';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebaseConfig';
import useAuthListener from './components/UserAuthListener';

function App() {

  const [user, setUser] = useState<User | null>(null);
  useAuthListener();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  return (
    <Router>
        <Routes>
            <Route path="/" element={user  ? <ProtectedPage /> :  <AuthPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </Router>
  );

}

export default App;
