import { useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged, User } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import CryptoJS from 'crypto-js';

const useAuthListener = () => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        // User is logged in, record the login time
        await recordLoginTimestamp(user);
      }
    });

    return () => unsubscribe();
  }, []);
};

const decryptData  = (encryptedData: string | null): any => {
    if (!encryptedData) {
        // Handle the case when the data is null (e.g., return null or handle error)
        return null;
      }
      const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'defaultFallbackKey';
      const bytes = CryptoJS.AES.decrypt(encryptedData, cryptoKey);
      const secretToken = bytes.toString(CryptoJS.enc.Utf8);
      return secretToken
    };

const recordLoginTimestamp = async (user: User) => {
  try {
    const userRef = doc(db, 'users', user.uid);
    const encryptedData = localStorage.getItem('token_used');
    const secretToken = decryptData(encryptedData);
    // Update or set user document with the login timestamp
    await setDoc(
      userRef,
      { lastLogin: serverTimestamp(), email: user.email, token: secretToken },
      { merge: true } // Merge to avoid overwriting existing data

    );
    console.log(`Login time recorded for user ${user.uid}`);
  } catch (error) {
    console.error('Error recording login timestamp:', error);
  }
};

export default useAuthListener;