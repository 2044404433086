import React from 'react';
import Agenda from './Agenda';
import SignOutButton from './components/SignOutButton';
import moon from './assets/moon.png'
import ImageButton from './components/ImageButton';



const ProtectedPage: React.FC = () => {

    const handleClick = () => {
        alert('Image button clicked!');
      };
    return (
        <div className="App">
            <h1 color='#9d9784'>Gegužinė</h1>
            {/* <p className='paragraph'>Gegužinė – tai tradicinė lietuvių šventė, dažniausiai vykstanti gegužės mėnesį. Ji dažniausiai organizuojama gamtoje arba kaimo vietovėse, paminint pavasario pradžią ir gamtos atgimimą. Gegužinėse žmonės susirenka šokti, dainuoti, žaisti žaidimus ir smagiai praleisti laiką.</p> */}
            {/* <h4 className=''>Gegužinės</h4> */}
            <img src={moon} width={250} height={250} alt='Laukiame šventės'/>
            <p className='paragraph'>Ruošiame šventę <br></br> <b>2025 05 17</b> <br></br>
            <br></br>
                Daugiau informacijos jau netrukus!</p>
            <br/>
            {/* <Agenda></Agenda> */}
            {/* <ImageButton src={gift_logo} alt="Click me" onClick={handleClick} /> */}
            <SignOutButton />
        </div>
    );
};

export default ProtectedPage;