import React, { useState } from 'react';
import {auth, db} from './firebaseConfig'
import GoogleSignInButton from './components/GoogleSingInButton';
import { doc, getDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import auth_logo from './assets/auth.png'
auth.languageCode = 'lt';

const AuthPage: React.FC = () => {

  const [secretToken, setSecretToken] = useState<string>(''); // For storing the secret token input
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null); // Token validation state
  const [showGoogleSignIn, setShowGoogleSignIn] = useState<boolean>(false); // Show/hide Google sign-in button

  // Step 1: Validate the secret token
  const validateToken = async () => {
    if (!secretToken) {
      return;
    }

    try {
      const tokenDocRef = doc(db, 'tokens', secretToken); // Reference to the token in Firestore
      const tokenDoc = await getDoc(tokenDocRef);

      if (tokenDoc.exists()) {
        setIsTokenValid(true);
        console.log('Token is valid, proceeding to Google sign-in');
        setShowGoogleSignIn(true); // Set the user as authenticated
        const times_used =  tokenDoc.data().used+1
        localStorage.setItem('times_used', times_used);
        const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'defaultFallbackKey';
        const encryptedData = CryptoJS.AES.encrypt(secretToken, cryptoKey).toString();
        localStorage.setItem('token_used', encryptedData)
      } else {
        setIsTokenValid(false);
      }
    } catch (error) {
      console.error('Error validating token:', error);
      setIsTokenValid(false);
    }
  };

  return (
      <div>
        {!showGoogleSignIn  ? (
          <div className="App">

            <img src={auth_logo} width={250} height={250} style={{ marginLeft: '-20px' }} alt='Gegužinė su mumis'/>
            <div className='container'>
            <input className="input-label"
              type="text"
              placeholder="slaptažodis"
              value={secretToken}
              onChange={(e) => setSecretToken(e.target.value)}
            />
            <button className="custom-button" onClick={validateToken}>Tikrinti slaptažodį</button>

            {isTokenValid === false && <p>Slaptažodis neteisingas, bandykite dar kartą</p>}
            </div>
          </div>
        ) : (
          <GoogleSignInButton/>
        )}
      </div>
  );
};

export default AuthPage;