import React from 'react';
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";  // Assuming firebase is initialized in firebase.ts

const SignOutButton: React.FC = () => {
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully.");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <button className='custom-button' onClick={handleSignOut}>
      Atsijungti
    </button>
  );
};

export default SignOutButton;